var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.show)?_c('div',{staticClass:"hm-group__wrapper"},[(!_vm.hideName)?_c('div',{staticClass:"hm-group__title"},[_vm._v(" "+_vm._s(_vm.name)+" ")]):_vm._e(),_c('hm-form-fields',{class:{
      'hm-group' : true,
      'hm-group--horizontal': _vm.mode === 'horizontal',
      'hm-group--without-border': _vm.hideBorder,
    },attrs:{"tag":"div","fields":_vm.fields,"global-state":_vm.globalState,"group-links":_vm.groupLinks},on:{"change":_vm.onInput},model:{value:(_vm.localValue),callback:function ($$v) {_vm.localValue=$$v},expression:"localValue"}}),(_vm.addMore)?_c('esmp-button',{staticClass:"add-group",attrs:{"disabled":_vm.isAddMoreBtnDisabled,"size":"small"},on:{"click":_vm.addGroup}},[_vm._v(" Добавить еще ")]):_vm._e(),_vm._l((_vm.addedGroups),function(group){return [_c('hm-form-fields',{key:group.id,class:{
        'hm-group' : true,
        'hm-group--added' : true,
        'hm-group--horizontal': _vm.mode === 'horizontal',
        'hm-group--without-border': _vm.hideBorder,
      },attrs:{"tag":"div","fields":group.fields,"global-state":_vm.globalState,"group-links":_vm.groupLinks,"parent-group-id":group.id},on:{"change":_vm.onAddedGroupChange},model:{value:(group.value),callback:function ($$v) {_vm.$set(group, "value", $$v)},expression:"group.value"}}),_c('esmp-button',{key:`${group.id}-remove`,staticClass:"remove-group",attrs:{"size":"small","view":"outline"},on:{"click":function($event){return _vm.removeGroup(group.id)}}},[_vm._v(" Удалить ")]),_c('esmp-button',{key:`${group.id}-add`,staticClass:"add-group",attrs:{"disabled":_vm.isAddMoreBtnDisabled,"size":"small"},on:{"click":_vm.addGroup}},[_vm._v(" Добавить еще ")])]})],2):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }