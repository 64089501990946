<template>
  <div
    v-if="show"
    class="hm-group__wrapper"
  >
    <div v-if="!hideName" class="hm-group__title">
      {{ name }}
    </div>
    <hm-form-fields
      tag="div"
      :fields="fields"
      v-model="localValue"
      :global-state="globalState"
      :group-links="groupLinks"
      :class="{
        'hm-group' : true,
        'hm-group--horizontal': mode === 'horizontal',
        'hm-group--without-border': hideBorder,
      }"
      @change="onInput"
    />
    <esmp-button
      v-if="addMore"
      :disabled="isAddMoreBtnDisabled"
      size="small"
      class="add-group"
      @click="addGroup"
    >
      Добавить еще
    </esmp-button>
    <template v-for="group in addedGroups">
      <hm-form-fields
        :key="group.id"
        tag="div"
        :fields="group.fields"
        v-model="group.value"
        :global-state="globalState"
        :group-links="groupLinks"
        :parent-group-id="group.id"
        :class="{
          'hm-group' : true,
          'hm-group--added' : true,
          'hm-group--horizontal': mode === 'horizontal',
          'hm-group--without-border': hideBorder,
        }"
        @change="onAddedGroupChange"
      />
      <esmp-button
        :key="`${group.id}-remove`"
        size="small"
        view="outline"
        class="remove-group"
        @click="removeGroup(group.id)"
      >
        Удалить
      </esmp-button>
      <esmp-button
        :disabled="isAddMoreBtnDisabled"
        :key="`${group.id}-add`"
        size="small"
        class="add-group"
        @click="addGroup"
      >
        Добавить еще
      </esmp-button>
    </template>
  </div>
</template>

<script>
import { uniqueId } from 'lodash';
import checkVisible from './checkVisible';
import HmFormFields from '../../HmFormFields.vue';

export default {
  name: 'HmGroup',
  components: { HmFormFields },
  model: {
    prop: 'value',
    event: 'input',
  },
  props: {
    id: {
      type: [String, Number],
      default: undefined,
    },
    value: {
      type: Object,
      default: () => ({}),
    },
    mode: {
      type: String,
      default: 'vertical',
    },
    name: {
      type: String,
      default: '',
    },
    fields: {
      type: Array,
      default: () => [],
    },
    visible: {
      type: Boolean,
      default: false,
    },
    hideBorder: {
      type: Boolean,
      default: false,
    },
    hideName: {
      type: Boolean,
      default: false,
    },
    addMore: {
      type: Boolean,
      default: false,
    },
    globalState: {
      type: Object,
      default: () => ({}),
    },
    groupLinks: {
      type: Array,
      default: undefined,
    },
    maxNumberOfGroupCopies: {
      type: Number,
      default: null,
    },
    parentGroupId: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      addedGroups: [],
      localValue: this.value,
      show: checkVisible(this.visible, this.globalState, this.id, this.groupLinks, this.parentGroupId),
    };
  },
  computed: {
    isAddMoreBtnDisabled() {
      if (this.addMore) {
        if (!this.maxNumberOfGroupCopies) return false;
        return this.addedGroups.length >= this.maxNumberOfGroupCopies;
      }
      return true;
    },
  },
  watch: {
    globalState: {
      handler(v) {
        this.show = checkVisible(this.visible, v, this.id, this.groupLinks, this.parentGroupId);
      },
      deep: true,
      immediate: true,
    },
    show: {
      handler(v) {
        if (!v) this.$emit('input', {});
      },
      deep: true,
    },
  },
  methods: {
    addGroup() {
      this.addedGroups.push({ id: uniqueId(), fields: [...this.fields], value: {} });
    },
    removeGroup(id) {
      this.addedGroups = this.addedGroups.filter((el) => el.id !== id);
      this.onAddedGroupChange();
    },
    onAddedGroupChange() {
      const addedGroups = this.addedGroups.map((el) => ({
        id: el.id,
        value: el.value,
      }));
      this.localValue.addedGroups = addedGroups;
      this.onInput({ addedGroups });
    },
    onInput(value) {
      this.$emit('input', { ...this.localValue, ...value });
    },
  },
};
</script>
<style lang="scss" scoped>
.hm-group {
  width: 100%;
  margin: 8px 0 0 0!important;
  padding: 24px;
  border-radius: 12px;
  border: 1px solid #E8E8EE;
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-wrap: wrap;
  &__title {
    margin-left: 12px;
  }
  @include for-size(phone-portrait-down) {
    padding: 7px 12px;
  }
  ::v-deep .hm-form__element {
    margin: 10px 0;
    &:first-child {
      margin-top: 0;
    }
    &:last-child {
      margin-bottom: 0;
    }
  }
  &--horizontal {
    padding: 14px;
    flex-direction: row;
    @include for-size(phone-portrait-down) {
      padding: 7px;
    }
    ::v-deep .hm-form__element {
      margin: 10px;
      flex: 1 1 auto;
      min-width: calc(33.33% - 20px);
      @include for-size(phone-portrait-down) {
        margin: 5px;
        min-width: calc(33.33% - 10px);
      }
    }
  }
  &--added {
    margin-top: 20px!important;
  }
  &--without-border {
    border: none;
    padding: 0;
    margin: 0 !important;
  }
}
.hm-group--horizontal.hm-group--without-border {
  width: calc(100% + 20px);
  margin: 8px -10px 0!important;
}
.add-group, .remove-group {
  margin: 20px 0 0 0;
}
.remove-group {
  margin-right: 20px;
}
</style>
